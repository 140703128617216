<!--需求管理页面-->
<template>
  <div>
    <!--面包屑区域-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <!--顶部数据展示-->

    <el-card>
      <template>
        <el-form :inline="true"
                 :model="formInline"
                 class="demo-form-inline">
          <el-form-item label="需求名称："
                        size="mini">
            <el-input v-model="formInline.demandName"
                      placeholder="请输入需求名称"
                      size="mini"></el-input>
          </el-form-item>
          <el-form-item label="版本号："
                        prop="version"
                        size="mini">
            <el-select v-model="formInline.version"
                       multiple
                       placeholder="请选择版本号">
              <el-option v-for="version in versionList"
                         :key="version.id"
                         :label="version.name"
                         :value="version.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属系统："
                        prop="systemName"
                        size="mini">
            <el-select v-model="formInline.systemName"
                       multiple
                       placeholder="请选择系统">
              <el-option v-for="system in systemList"
                         :key="system.id"
                         :label="system.name"
                         :value="system.name">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item size="mini">
            <el-button type="primary">查询</el-button>

          </el-form-item>
        </el-form>

        <el-tabs v-model="tabName"
                 @tab-click="handleClick">
          <el-tab-pane label="
                 待发起"
                       name="first">
            <div>
              <el-button type="primary"
                         style="margin-bottom: 10px;"
                         @click="openAddDemandVisible">添加需求</el-button>
              <div class="table_box">

                <el-table :data="demandList"
                          border
                          height="600"
                          style="width: 96%;"
                          align="center">

                  <el-table-column label="序号"
                                   width="60px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span>{{ scope.$index + 1 }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="需求编号"
                                   width="120px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span>{{ scope.row.id }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="需求名称"
                                   width="">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span size="medium">{{ scope.row.demandName }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="原型地址"
                                   width="">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <a :href="scope.row.demandUrl"
                           target="_blank">
                          <el-tag size="medium">{{ scope.row.demandUrl }}</el-tag>
                        </a>
                      </div>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column label="所属系统"
                                   width="100px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">

                        <span size="medium">{{ scope.row.systemName }}</span>

                      </div>
                    </template>
                  </el-table-column> -->
                  <el-table-column label="创建人"
                                   width="100px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span size="medium">{{ scope.row.creatorName }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="创建时间">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span size="medium">{{ scope.row.createTime }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="需求状态"
                                   width="100px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span size="medium">{{ scope.row.demandStatusStr }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-button size="mini"
                                 @click="selectDemand(scope.row)">查看</el-button>
                      <el-button size="mini"
                                 @click="openEditDemand(scope.row)">修改</el-button>

                      <el-button size="mini"
                                 type="primary"
                                 @click="openConfirmDialogVisible(scope.row.id)">发布需求</el-button>

                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="进行中"
                       name="second">
            <div>

              <div class="table_box">

                <el-table :data="activeDemandList"
                          border
                          style="width: 96%;"
                          height="640"
                          align="center">

                  <el-table-column label="序号"
                                   width="60px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span>{{ scope.$index + 1 }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="需求编号"
                                   width="120px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span>{{ scope.row.id }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="需求名称"
                                   width="">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span size="medium">{{ scope.row.demandName }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="原型地址"
                                   width="">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <a :href="scope.row.demandUrl"
                           target="_blank">
                          <el-tag size="medium">{{ scope.row.demandUrl }}</el-tag>
                        </a>
                      </div>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column label="所属系统"
                                   width="100px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">

                        <span size="medium">{{ scope.row.systemName }}</span>

                      </div>
                    </template>
                  </el-table-column> -->
                  <el-table-column label="创建人"
                                   width="100px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span size="medium">{{ scope.row.creatorName }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="创建时间">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span size="medium">{{ scope.row.createTime }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="需求状态"
                                   width="100px">
                    <template slot-scope="scope">
                      <div slot="reference"
                           class="name-wrapper">
                        <span size="medium">{{ scope.row.demandStatusStr }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作"
                                   width="180">
                    <template slot-scope="scope">
                      <el-button size="mini"
                                 @click="demandDetail(scope.row.id)">查看</el-button>
                      <el-button size="mini"
                                 type="danger"
                                 @click="openDeleteVisible(scope.row.id)">作废</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="已完成"
                       name="third">
            <div class="table_box">

              <el-table :data="historyDemandList"
                        border
                        height="640"
                        align="center">

                <el-table-column label="序号"
                                 width="60px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span>{{ scope.$index + 1 }} </span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="需求编号"
                                 width="120px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span>{{ scope.row.id }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="需求名称"
                                 width="">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.demandName }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="原型地址"
                                 width="160px">

                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <a :href="scope.row.demandUrl"
                         target="_blank">
                        <el-tag size="medium">{{ scope.row.demandUrl }}</el-tag>
                      </a>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="需求状态"
                                 width="100px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <div v-if="scope.row.demandStatus === '08'">
                        <el-tag type="success">{{ scope.row.demandStatusStr }}</el-tag>
                      </div>
                      <div v-else>
                        <el-tag type="danger">{{ scope.row.demandStatusStr }}</el-tag>
                      </div>

                    </div>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="所属系统"
                                 width="80px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">

                      <span size="medium">{{ scope.row.systemName }}</span>

                    </div>
                  </template>
                </el-table-column> -->
                <el-table-column label="创建人"
                                 width="80px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.creatorName }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="创建时间"
                                 width="160px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.createTime }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="提测时间"
                                 width="160px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.demandStatusStr }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="上线时间"
                                 width="160px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.endTime }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="工时(时/人/天)"
                                 width="150px">
                  <template slot-scope="scope">
                    <div slot="reference"
                         class="name-wrapper">
                      <span size="medium">{{ scope.row.header }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button size="mini"
                               @click="demandDetail(scope.row.id)">查看</el-button>

                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>

        </el-tabs>

      </template>
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="pageQuery.current"
                     :page-sizes="[10, 20, 30, 40]"
                     :page-size="pageQuery.size"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>
    <el-dialog title="添加需求"
               :visible.sync="dialogFormVisible"
               width="30%"
               :close-on-click-modal="false">
      <el-form :model="demandForm"
               :rules="demandDetailRules"
               ref="demandForm">
        <!-- <el-form-item label="需求版本："
                      :label-width="formLabelWidth"
                      prop="version">
          <el-input v-model="demandForm.version"
                    placeholder="请输入版本号"
                    style="width: 218px;"
                    :disabled="is_edit">

          </el-input>
        </el-form-item> -->
        <el-form-item label="需求名称："
                      :label-width="formLabelWidth"
                      prop="demandName">
          <el-input v-model="demandForm.demandName"
                    placeholder="请输入需求名称"
                    :disabled="is_edit">

          </el-input>
        </el-form-item>
        <el-form-item label="需求类型："
                      :label-width="formLabelWidth"
                      prop="demandType">
          <el-select v-model="demandForm.demandType"
                     placeholder="请选择需求类型"
                     :disabled="is_edit">
            <el-option v-for="i in demandType"
                       :key="i.key"
                       :label="i.value"
                       :value="i.key"></el-option>

          </el-select>
        </el-form-item>
        <el-form-item label="原型地址："
                      :label-width="formLabelWidth"
                      prop="demandUrl">

          <el-input v-model="demandForm.demandUrl"
                    placeholder="请输入需求地址"
                    :disabled="is_edit">

          </el-input>
        </el-form-item>

        <el-form-item label="需求描述："
                      :label-width="formLabelWidth"
                      prop="demandDescription">
          <el-input type="textarea"
                    placeholder="请输入内容"
                    v-model="demandForm.demandDescription"
                    :rows="10"
                    maxlength="300"
                    show-word-limit
                    :disabled="is_edit"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button v-if="is_edit === false"
                   @click="cancel">取 消</el-button>
        <el-button v-else
                   @click="selectCancel">取 消</el-button>

        <el-button v-if="is_update === false"
                   type="primary"
                   @click="addDemand"
                   v-show="is_show">确 定</el-button>

        <el-button v-if="is_update === true"
                   type="primary"
                   @click="editDemand"
                   v-show="is_show">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="提示"
               :visible.sync="confirmDialogVisible"
               width="30%"
               :close-on-click-modal="false">
      <span>是否发起该需求？</span>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="confirmDialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="initiate">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示"
               :visible.sync="deleteVisible"
               width="30%">
      <span>确定作废该条需求吗？</span>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="deleteDemand">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {

  data () {
    return {
      formInline: {
        version: '',
        systemName: '',
        demandName: ''

      },
      versionList: [
        { id: '011', name: 'V4.0.1' },
        { id: '012', name: 'V0.1.1' },
        { id: '013', name: 'V1.0.3' }
      ],
      systemList: [
        { id: '001', name: '金云e诺' },
        { id: '002', name: '电子债权' },
        { id: '003', name: '数据融资' }
      ],
      pageQuery: {
        current: 1,
        size: 10,
        username: '',
        nickname: ''
      },
      total: 0,
      is_edit: false,
      is_show: true,

      // 当前活动页面路径
      atactivePath: '',
      // 定义当前活动导航的名称
      activeName: '',
      // 定义当前活动导航父级导航名称
      parentName: '',
      demandList: [], // 待发起列表
      activeDemandList: [], // 已发起列表
      historyDemandList: [], // 已上线需求列表
      formLabelWidth: '120px',
      demandForm: {
        systemName: '', // 系统名称
        demandType: '', // 需求类型
        demandName: '', // 需求名称
        demandDescription: '', // 需求描述
        version: '', // 需求版本
        demandUrl: '' // 需求原型地址

      },
      demandId: '', // 需求id 用于发起需求
      dialogFormVisible: false,
      tabName: 'first',

      demandHistoryList: [
        { id: '1', name: '去掉账号密码登录', envAddr: '电子债权', creatTime: '2022.10.10', endTime: '2022.10.10', author: '杨华龙', is_Schedule: '已上线', header: '杨帆' }
      ],
      demandType: [], // 需求类型
      systemType: [], // 环境类型
      confirmDialogVisible: false,
      demandDetailRules: {
        systemName:
          [{ required: true, message: '请选择环境名称', trigger: 'blur' }],

        demandType:
          [{ required: true, message: '请选择需求类型', trigger: 'blur' }], // 需求类型
        demandName:
          [{ required: true, message: '请输入需求名称', trigger: 'blur' }], // 需求名称
        demandDescription:
          [{ required: true, message: '请输入需求描述', trigger: 'blur' }], // 需求描述
        // version:
        //   [{ required: true, message: '请输入版本号', trigger: 'blur' }], // 需求版本
        demandUrl:
          [{ required: true, message: '请输入原型地址', trigger: 'blur' }] // 需求原型地址
      },
      is_update: false,
      deleteDemandId: '', //
      deleteVisible: false
    }
  },

  created () {
    this.activePath = window.sessionStorage.getItem('activePath')
    this.activeName = window.sessionStorage.getItem('activeName')
    this.parentName = window.sessionStorage.getItem('parentName')

    this.getDemandList()


  },

  methods: {
    handleClick (tab) {
      this.tabName = tab.name
      if (this.tabName === 'first') {
        this.getDemandList()
      } else if (this.tabName === 'second') {
        this.getActiveDemandList()
      } else if (this.tabName === 'third') {
        this.getHistoryDemandList()
      }
    },
    demandDetail (id) {
      window.sessionStorage.setItem('tabName', 'first')
      window.sessionStorage.setItem('demandId', id)
      this.$router.push('/demandDetail')
    },
    openAddDemandVisible () {
      this.getDemandType()
      this.dialogFormVisible = true
      this.is_update = false
    },
    testDemo () {
      this.$router.push('/testDemo')
    },
    async getDemandType () {
      const { data: res } = await this.$http.get('py/server/project/getDemandType')
      if (res.success === false) return this.$message.error(res.msg)
      this.demandType = res.data
    },
    async getSystemType () {
      const { data: res } = await this.$http.get('py/server/project/getSystemType')
      if (res.success === false) return this.$message.error(res.msg)
      this.systemType = res.data
    },
    async addDemand (formName) {
      this.$refs.demandForm.validate(async (valid) => {
        if (!valid) return
        const { data: res } = await this.$http.post('py/server/project/addDemand', this.demandForm)
        if (res.success === false) return this.$message.error(res.msg)
        this.getDemandList()
        this.$refs.demandForm.resetFields()
        this.dialogFormVisible = false
      })
    },
    setDemandType (e) {
      this.demandForm.demandType = e.value
    },
    setSystemName (e) {
      this.demandForm.systemName = e.value
    },
    async getDemandList () { // 获取待发起需求列表页方法
      const { data: res } = await this.$http.post('py/server/project/getWaitDemandInfoVOPage', this.pageQuery)
      if (!res.success) return this.$message.error(res.msg)
      this.demandList = res.data.records
      this.total = res.data.total
      console.log(res)
    },
    async getActiveDemandList () { // 获取已发起需求列表页方法
      const { data: res } = await this.$http.post('py/server/project/getDemandInfoVOPage', this.pageQuery)
      if (!res.success) return this.$message.error(res.msg)
      this.activeDemandList = res.data.records

      this.total = res.data.total
    },
    handleSizeChange (newPage) {
      this.pageQuery.size = newPage

      if (this.tabName === 'first') {
        this.getDemandList()
      } else if (this.tabName === 'second') {
        this.getActiveDemandList()
      } else if (this.tabName === 'third') {
        this.getHistoryDemandList()
      }
    },
    handleCurrentChange (newCurrent) {
      this.pageQuery.current = newCurrent

      if (this.tabName === 'first') {
        this.getDemandList()
      } else if (this.tabName === 'second') {
        this.getActiveDemandList()
      } else if (this.tabName === 'third') {
        this.getHistoryDemandList()
      }
    },
    cancel () {
      this.dialogFormVisible = false

      this.demandForm = {
        systemName: '', // 系统名称
        demandType: '', // 需求类型
        demandName: '', // 需求名称
        demandDescription: '', // 需求描述
        version: '', // 需求版本
        demandUrl: '' // 需求原型地址

      }
    },
    selectCancel () {
      this.is_edit = false
      this.is_show = true
      this.dialogFormVisible = false
      this.demandForm = {
        systemName: '', // 系统名称
        demandType: '', // 需求类型
        demandName: '', // 需求名称
        demandDescription: '', // 需求描述
        version: '', // 需求版本
        demandUrl: '' // 需求原型地址
      }
    },
    openConfirmDialogVisible (id) {
      this.confirmDialogVisible = true
      this.demandId = id
    },
    async initiate () {
      const { data: res } = await this.$http.post('py/server/project/initiate?id=' + this.demandId)
      if (!res.success) return this.$message.error(res.msg)

      this.confirmDialogVisible = false
      this.getDemandList()
      this.$message.success(res.msg)
    },
    selectDemand (data) {
      this.is_edit = true
      this.is_show = false
      this.dialogFormVisible = true
      this.demandForm = data
    },
    openVisible () {
      this.is_update = false
      this.dialogFormVisible = true
      this.$refs.demandForm.resetFields()
    },
    openEditDemand (data) {
      this.is_update = true
      this.dialogFormVisible = true
      this.demandForm = data
    },
    async getHistoryDemandList () {
      const { data: res } = await this.$http.post('py/server/project/getOnlineDemandInfoVOPage', this.pageQuery)
      if (!res.success) return this.$message.error(res.msg)
      this.historyDemandList = res.data.records
    },
    async editDemand () {
      const { data: res } = await this.$http.post('api/server/project/editDemand', this.demandForm)
      if (!res.success) return this.$message.error(res.msg)
      this.dialogFormVisible = false
      this.$message.success('修改成功')
      this.getDemandList()
      this.$refs.demandForm.resetFields()
    },
    openDeleteVisible (id) {
      this.deleteVisible = true
      this.deleteDemandId = id
    },
    async deleteDemand (id) {

      const { data: res } = await this.$http.post('api/server/project/cancellation?id=' + this.deleteDemandId)
      if (res.success) {
        this.$message.success('作废成功')
        this.deleteVisible = false
        this.getActiveDemandList()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
