<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div style="margin-bottom: 20px;">
        <el-input placeholder="搜索项目"
                  prefix-icon="el-icon-search"
                  v-model="inputText"
                  style="width:150px"
                  size="mini">
        </el-input>
        <el-button type="primary"
                   size="mini"
                   style="margin-left: 15px;"
                   icon="el-icon-plus"
                   @click="openAddObjVisible">新增项目</el-button>

      </div>
      <el-card>
        <el-table :data="objList"
                  style="width: 70%"
                  height="600px">
          <el-table-column label="序号"
                           width="80">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="项目名称">
            <template slot-scope="scope">
              <div style="margin-left: 30px;cursor:pointer;"
                   @click="objDetail(scope.row)">
                <!-- <img src="./obj_icon.png"
                     style="width:40px"> -->
                <span style="font-size: 16px;">{{ scope.row.title }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           width="300">
            <template slot-scope="scope">

              <el-button size="mini"
                         @click="handleEdit(scope.$index, scope.row)">修改</el-button>
              <el-button size="mini"
                         type="danger"
                         @click="handleDelete(scope.$index, scope.row)">删除项目</el-button>
            </template>
          </el-table-column>

        </el-table>
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="queryList.current"
                       :page-sizes="[10, 20, 30, 40]"
                       :page-size="queryList.size"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </el-card>

    </el-card>

    <el-dialog title="新增项目"
               :visible.sync="addObjVisible"
               width="25%">
      <el-form :model="obj_info">
        <el-form-item label="项目名称">
          <el-input v-model="obj_info.title"
                    style="width: 80%;"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="closeAddObjVisible">取 消</el-button>
        <el-button type="primary"
                   @click="saveObj">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import ApiDetail from './ApiDetail.vue'
export default {
  components: {
    ApiDetail
  },

  data () {
    return {
      obj_info: {
        title: '',
        id: ''
      },
      addObjVisible: false,

      activeName: '', // 当前活动菜单名称
      activePath: '', // 当前活动菜单 路由
      parentName: '', // 当前活动菜单，父级菜单名称
      activeTabName: 'first',
      inputText: '', // 输入框的文本内容,
      objList: [

      ], // 项目列表
      queryList: {
        title: '',
        current: 1,
        size: 10
      },
      total: 0,
      obj_id: ''
    }
  },

  created () {
    this.activePath = window.sessionStorage.getItem('activePath')
    this.activeName = window.sessionStorage.getItem('activeName')
    this.parentName = window.sessionStorage.getItem('parentName')
    this.getObjList()
  },

  methods: {

    objDetail (row) {
      console.log(row)
      this.$router.push({
        path: '/objDetail',
        query: { obj_id: row.id }

      })
    },
    async getObjList () {
      const { data: res } = await this.$http.post('py/server/autoApi/getObjList', this.queryList)
      if (res.success == true) {
        this.objList = res.data.records

        this.total = res.data.total
      } else {
        this.$message.error(res.msg)
      }
    },
    handleSizeChange (newPage) {
      /**
       * @description：用于分页
       */
      this.getObjList.size = newPage
      this.getObjList()
    },
    handleCurrentChange (newCurrent) {
      /**
       * @description：用于分页
       */
      this.queryList.current = newCurrent
      this.getObjList()

    },

    handleEdit () {

    },
    handleDelete () {

    },
    async saveObj () {
      const { data: res } = await this.$http.post('py/sever/autoApi/saveObj', this.obj_info)

      if (res.success) {
        this.closeAddObjVisible()
        this.$message.success(res.msg)
        this.getObjList()
      } else {
        this.$message.error(res.msg)
      }
    },
    openAddObjVisible () {
      this.addObjVisible = true
    },
    closeAddObjVisible () {
      this.addObjVisible = false
      this.obj_info = {
        title: '',
        id: ''
      }
    }

  }

}

</script>

<style lang="less" scoped>
</style>
