<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ obj_title }}</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-page-header @back="goBack"
                      :content="detailTitle"
                      style="margin-bottom: 20px">
      </el-page-header>
      <el-button type="primary"
                 style="margin-bottom: 20px;"
                 icon="el-icon-plus"
                 @click="addApi">添加接口</el-button>
      <div>
        <el-table :data="apiList"
                  border
                  height="600px">
          <el-table-column fixed
                           prop="id"
                           label="接口编号"
                           width="120px">
          </el-table-column>
          <el-table-column prop="title"
                           label="接口描述"
                           width="400">
          </el-table-column>
          <el-table-column prop="method"
                           label="请求方式"
                           width="100px">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.method == 'post' || scope.row.method == 'POST'">{{ scope.row.method }}</el-tag>
              <el-tag v-else
                      type="success">{{ scope.row.method }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="api_path"
                           label="接口地址"
                           width="400">
          </el-table-column>
          <el-table-column prop="creator"
                           label="创建人"
                           width="120">
          </el-table-column>
          <el-table-column prop="update_time"
                           label="更新时间"
                           width="180">
          </el-table-column>

          <el-table-column label="操作"
                           width="100">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row)"
                         type="text"
                         size="small">查看</el-button>
              <el-button type="text"
                         size="small">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog title="新增接口"
                 :visible.sync="addApiVisible"
                 width="60%"
                 destroy-on-close="true">
        <api-detail ref="detailRef"></api-detail>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import apiTable from "../common/ApiTable.vue";
import apiDetail from "./ApiDetail.vue";

export default {
  components: {
    apiDetail,
    apiTable,
  },
  props: {
    data_list: [],
  },
  data () {
    return {
      obj_id: this.$route.query.obj_id || "",
      addApiRes: false,
      addApiVisible: false,
      apiList: [],
      tabPosition: "left",
      radio: 1,
      methodList: [
        { key: "02", value: "POST" },
        { key: "01", value: "GET" },
        { key: "03", value: "PUT" },
        { key: "04", value: "DELETE" },
      ],
      formInline: {
        api_path: "",
        method: "POST",
      },
      items: [
        { name: "Item 1", quantity: 1, price: 10 },
        { name: "Item 2", quantity: 2, price: 20 },
        { name: "Item 3", quantity: 3, price: 30 },
      ],
      activeName: "first",
      obj_title: "",
      detailTitle: "",
      api_data: {
        id: "",
        obj_id: "",
        title: "",
        api_path: "",
        method: "POST",
        data_type: "",
        headers_data: [{ key: "", value: "" }],
        params_data: [{ key: "", value: "" }],
        body_data: [{ key: "", value: "" }],
        json_data: "",
        pre_data: [

        ],
        post_data: [

        ],

        assert_data: [
          {
            assert_type: "",
            data: "",
          },
        ],
      },
    };
  },
  created () {
    this.activePath = window.sessionStorage.getItem("activePath");
    this.activeName = window.sessionStorage.getItem("activeName");
    this.parentName = window.sessionStorage.getItem("parentName");
    this.getApiList();

  },
  computed: {
    res () {
      console.log(this.$store.state.addApiResult)
      return this.$store.state.addApiResult
    }
  },
  watch: {
    res: {
      handler (newValue, oldValue) {
        console.log(newValue)
        if (newValue == true) {
          this.closeAddApiVisible()
          this.getApiList()
        }
      },
      immediate: true
    }

  },
  methods: {

    radio_change (x) {
      console.log(x);
    },
    onSubmit () { },
    resetApiData () {
      this.api_data = {
        id: "",
        obj_id: "",
        title: "",
        api_path: "",
        method: "POST",
        data_type: "",
        headers_data: [{ key: "", value: "" }],
        params_data: [{ key: "", value: "" }],
        body_data: [{ key: "", value: "" }],
        json_data: "",
        pre_data: [
        ],
        post_data: []
      };
    },
    async getApiList () {

      const { data: res } = await this.$http.get(
        "py/server/autoApi/getApiList?obj_id=" + this.obj_id
      );

      if (res.success) {
        this.apiList = res.data
      } else {
        this.$message.error(res.msg)
      }

    },
    tabClick (args) {
      const tabName = args._props.name;
      const tabLabel = args._props.label;

    },
    goBack () {
      this.$router.back(0);
    },
    apiDeatilClick (events) {
      this.detailTitle = events.label;
      const api_id = events.name;

    },
    addApi () {
      this.addApiVisible = true
      this.$store.commit('changeCommit', false)
      this.$nextTick(() => {
        this.api_data.obj_id = this.obj_id
        this.$refs.detailRef.apiInfo = this.api_data
        this.$refs.detailRef.obj_id = this.obj_id;
      });

    },
    closeAddApiVisible () {
      this.addApiVisible = false
    },
    handleClick (row) {

      this.addApiVisible = true;
      this.$nextTick(() => {
        this.$refs.detailRef.apiInfo = row;
        this.$refs.detailRef.obj_id = this.obj_id;
        if (row.response != null || row.response != {}) {
          this.$refs.detailRef.res_json = row.response
        } else {
          this.$refs.detailRef.res_json = {}
        }

      });
    },
  },
};
</script>

<style>
.el-row {
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #042a82;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
