
<template>

  <div>
    <!--面包屑区域-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
      <!-- <el-breadcrumb-item>需求详情页</el-breadcrumb-item>
      <el-breadcrumb-item>新增BUG</el-breadcrumb-item> -->
    </el-breadcrumb>
    <el-card>
      <search-component :key="timer"
                        :itemList="searchList"
                        :formData="searchData">
      </search-component>
      <div class="my_boox">
        <el-table :data="env_list"
                  style="width: 100%"
                  stripe
                  height="700px">
          <el-table-column label="序号"
                           width="60">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="环境名称"
                           width="140">
            <template slot-scope="scope">
              <el-popover trigger="hover"
                          placement="top">
                <div slot="reference"
                     class="name-wrapper">

                  <a :href="scope.row.url"
                     target="_blank"><el-tag size="medium">{{ scope.row.env_name }}</el-tag></a>

                </div>
              </el-popover>
            </template>

          </el-table-column>
          <el-table-column label="便捷导航"
                           width="180">
            <template slot-scope="scope">

              <a :href="scope.row.admin_url"
                 style="margin-right: 8px;"
                 target="_blank"><el-tag size="medium"
                        type="warning">admin</el-tag></a>

              <a :href="scope.row.sws_url"
                 target="_blank"><el-tag size="medium"
                        style="margin-right: 8px;"
                        type="warning">sws</el-tag></a>
              <a :href="scope.row.cp_url"
                 target="_blank"><el-tag size="medium"
                        type="warning">cp</el-tag></a>
            </template>
          </el-table-column>
          <el-table-column label="关联需求"
                           width="100">
            <template slot-scope="scope">

              <el-select v-model="scope.row.issue"
                         clearable
                         placeholder="请选择"
                         @change="add_issue($event, scope.row.env_name)">
                <el-option v-for="item in myIssueList"
                           :key="item.key"
                           :label="item.key + item.title"
                           :value="item.key">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="admin_be"
                           width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.serves_list.includes('admin_be')">
                <el-tag v-if="scope.row.serves_dict.admin_be != null"
                        type="danger">已占用({{ scope.row.serves_dict.admin_be }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>

              <!-- <el-tag v-if="scope.row.serves_list.includes('admin_be')"
                      type="danger">已占用({{ scope.row.serves_dict.admin_be }})</el-tag>
              <el-tag v-else
                      type="success">未占用</el-tag> -->

            </template>
          </el-table-column>
          <!-- <el-table-column label="admin_portal"
                           width="110">
            <template slot-scope="scope">
              <div v-if="scope.row.serves_list.includes('admin_portal')">
                <el-tag v-if="scope.row.serves_dict.admin_portal != null"
                        type="danger">已占用({{ scope.row.serves_dict.admin_portal }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column> -->

          <el-table-column label="sws_be"
                           width="100">
            <template slot-scope="scope">

              <div v-if="scope.row.serves_list.includes('sws_be')">
                <el-tag v-if="scope.row.serves_dict.sws_be != null"
                        type="danger">已占用({{ scope.row.serves_dict.sws_be }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>

              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="sws_portal"
                           width="110">
            <template slot-scope="scope">
              <div v-if="scope.row.serves_list.includes('sws_portal')">
                <el-tag v-if="scope.row.serves_dict.sws_portal != null"
                        type="danger">已占用({{ scope.row.serves_dict.sws_portal }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="ib_web"
                           width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.serves_list.includes('ib_web')">
                <el-tag v-if="scope.row.serves_dict.ib_web != null"
                        type="danger">已占用({{ scope.row.serves_dict.ib_web }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="client_portal"
                           width="110">
            <template slot-scope="scope">

              <div v-if="scope.row.serves_list.includes('client_portal')">
                <el-tag v-if="scope.row.serves_dict.client_portal != null"
                        type="danger">已占用({{ scope.row.serves_dict.client_portal }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="ib_portal"
                           width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.serves_list.includes('ib_portal')">
                <el-tag v-if="scope.row.serves_dict.ib_portal != null"
                        type="danger">已占用({{ scope.row.serves_dict.ib_portal }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="app_service"
                           width="110">
            <template slot-scope="scope">
              <div v-if="scope.row.serves_list.includes('app_service')">
                <el-tag v-if="scope.row.serves_dict.app_service != null"
                        type="danger">已占用({{ scope.row.serves_dict.app_service }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="open_api"
                           width="100">
            <template slot-scope="scope">

              <div v-if="scope.row.serves_list.includes('open_api')">
                <el-tag v-if="scope.row.serves_dict.open_api != null"
                        type="danger">已占用({{ scope.row.serves_dict.open_api }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="au_cron"
                           width="100">
            <template slot-scope="scope">

              <div v-if="scope.row.serves_list.includes('au_cron')">
                <el-tag v-if="scope.row.serves_dict.au_cron != null"
                        type="danger">已占用({{ scope.row.serves_dict.au_cron }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="liquibase"
                           width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.serves_list.includes('liquibase')">
                <el-tag v-if="scope.row.serves_dict.liquibase != null"
                        type="danger">已占用({{ scope.row.serves_dict.liquibase }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="payment_cron"
                           width="120">
            <template slot-scope="scope">
              <div v-if="scope.row.serves_list.includes('payment_cron')">
                <el-tag v-if="scope.row.serves_dict.payment_cron != null"
                        type="danger">已占用({{ scope.row.serves_dict.payment_cron }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </el-card>

  </div>
</template>

<script>
import SearchComponent from '../common/Serach.vue'
export default {
  components: {
    SearchComponent
  },
  data () {
    return {
      myIssueList: [],
      issue_value: '',
      timer: '',
      add_remark_form: {},
      env_list: [],
      searchData: {
        env_name: '',
        serves_list: []
      },
      resetData: {
        env_name: '',
        serves_list: []
      },
      searchList: [

        {
          label: '环境名称',
          prop: 'env_name',
          type: 'select',
          // multiple: true,
          options: [ // bug类型
            {
              label: 'Aardwolf',
              value: 'Aardwolf'
            },
            {
              label: 'Alpaca',
              value: 'Alpaca'
            },
            {
              label: 'Beaver',
              value: 'Beaver'
            },
            {
              label: 'Blowfish',
              value: 'Blowfish'
            },
            {
              label: 'Buffalo',
              value: 'Buffalo'
            },
            {
              label: 'Butterfly',
              value: 'Butterfly'
            },
            {
              label: 'Camel',
              value: 'Camel'
            },
            {
              label: 'Carrot',
              value: 'Carrot'
            },
            {
              label: 'Cauliflower',
              value: 'Cauliflower'
            },
            {
              label: 'Deer',
              value: 'Deer'
            },
            {
              label: 'Elephant',
              value: 'Elephant'
            },
            {
              label: 'Goat',
              value: 'Goat'
            },
            {
              label: 'Jaguar',
              value: 'Jaguar'
            },
            {
              label: 'Leopard',
              value: 'Leopard'
            }, {
              label: 'Monkey',
              value: 'Monkey'
            },
            {
              label: 'Pumpkin',
              value: 'Pumpkin'
            },
            {
              label: 'Sheep',
              value: 'Sheep'
            },
            {
              label: 'Squirrel',
              value: 'Squirrel'
            },
            {
              label: 'Tiger',
              value: 'Tiger'
            },
            {
              label: 'Whale',
              value: 'Whale'
            },
            {
              label: 'Core-marketo-01',
              value: 'Core-marketo-01'
            },
            {
              label: 'Core-marketo-02',
              value: 'Core-marketo-02'
            },
            {
              label: 'Core-marketo-03',
              value: 'Core-marketo-03'
            },

          ]
        },

        {
          label: '服务名称：',
          prop: 'serves_list',
          type: 'select',
          multiple: true,
          // multiple: true,
          options: [ // bug类型
            {
              label: 'admin_be',
              value: 'admin_be'
            },
            // {
            //   label: 'admin_portal',
            //   value: 'admin_portal'
            // },
            {
              label: 'sws_be',
              value: 'sws_be'
            }, {
              label: 'sws_portal',
              value: 'sws_portal'
            }, {
              label: 'ib_web',
              value: 'ib_web'
            }, {
              label: 'client_portal',
              value: 'client_portal'
            }, {
              label: 'ib_portal',
              value: 'ib_portal'
            }, {
              label: 'app_service',
              value: 'app_service'
            }, {
              label: 'open_api',
              value: 'open_api'
            }, {
              label: 'au_cron',
              value: 'au_cron'
            }, {
              label: 'liquibase',
              value: 'liquibase'
            },
          ]
        },

        // { label: '时间：', prop: 'date', type: 'daterange', valueFormat: 'yyyy-MM-dd' },
        {
          type: 'button',
          btnList: [
            { type: 'primary', label: '查询', size: 'mini', icon: 'el-icon-search', handle: row => this.search() },
            { type: 'info', label: '重置', size: 'mini', icon: 'el-icon-setting', handle: row => this.reset() },
            // { type: 'success', label: '获取最新数据', size: 'mini', icon: 'el-icon-setting', handle: row => this.downloadEvn() },
            // { type: 'success', label: '测试勿动', size: 'mini', icon: 'el-icon-setting', handle: row => this.test_test() },
            // { type: 'primary', label: '会议预约', size: 'mini', icon: 'el-icon-plus', handle: row => this.openDialog('add') }
          ]
        }
      ],

    }
  },

  created () {
    this.activePath = window.sessionStorage.getItem('activePath')
    this.activeName = window.sessionStorage.getItem('activeName')
    this.parentName = window.sessionStorage.getItem('parentName')
    // this.downloadEvn()
    this.serrch_env()
  },
  methods: {
    async downloadEvn () {
      const { data: res } = await this.$http.get('py/server/project/jira/downloadEnv')
      if (res.success == true) {
        this.$message.success("数据已更新")
        this.serrch_env()

      }

      else { this.$message.error(res.msg) }

    },
    async serrch_env (searchData) {
      const { data: res } = await this.$http.post('py/server/project/jira/getEnvList', this.searchData)

      if (res.success == true) {
        this.env_list = res.data
        console.log(this.env_list)
        this.getMyIssue()
        this.$message.success("环境查询成功")
      }

      else { this.$message.error(res.msg) }

    },

    search () {
      this.serrch_env(this.searchData)
    },
    reset () {
      this.searchData = {
        env_name: '',
        serves_list: []
      }
      this.timer = new Date().getTime()
      this.serrch_env(this.searchData)
    },
    async getMyIssue () {
      const { data: res } = await this.$http.get('py/server/project/jira/getMyIssue')
      if (res.success) {
        this.myIssueList = res.data
        console.log(this.myIssueList)
        // this.$message.success("需求获取成功")
      } else {
        this.$message.error(res.msg)
      }
    },
    async add_issue (issue_key, env_name) {
      this.add_remark_form.issue_key = issue_key
      this.add_remark_form.env_name = env_name
      const { data: res } = await this.$http.post('py/server/project/jira/addIssueRemark', this.add_remark_form)
      if (res.success) {
        this.$message.success(res.msg)
        this.add_remark_form = {}
      } else {
        this.$message.error(res.msg)
        this.add_remark_form = {}
      }
    },
    // async test_test () {
    //   const { data: res } = await this.$http.get('py/server/project/jira/test')

    //   this.env_list = res.data
    //   console.log(this.env_list)


    // }
  }
}
</script>


<style>
</style>