<template>
  <div>

    <el-form :model="apiInfo"
             class="demo-form-inline">
      <el-form-item label="接口描述">
        <el-input v-model="apiInfo.title"
                  placeholder="请输入接口描述"
                  style="width: 500px"></el-input>
      </el-form-item>

      <el-form-item label="接口信息">
        <el-select v-model="apiInfo.method"
                   style="width: 100px">
          <el-option v-for="method in methodList"
                     :key="method.key"
                     :label="method.value"
                     :value="method.value"></el-option>
        </el-select>

        <el-input v-model="apiInfo.api_path"
                  placeholder="请输入接口地址"
                  style="width: 390px;margin-left: 10px;"></el-input>
      </el-form-item>
      <el-form-item label="运行环境">
        <el-select v-model="apiInfo.host_path"
                   style="width: 100px;margin-right: 10px">
          <el-option v-for="host in hostList"
                     :key="host.id"
                     :label="host.name"
                     :value="host.id"></el-option>
        </el-select>
        <el-button style="background-color: blueviolet; color: white"
                   @click="sendRequest">发送</el-button>

        <el-button style="background-color: white"
                   @click="onSubmit">保存</el-button>
      </el-form-item>

    </el-form>

    <el-row>
      <el-col :span="16">
        <el-form>
          <el-tabs v-model="activeName"
                   @tab-click="tabClick">
            <el-tab-pane label="body"
                         name="second">
              <div style="margin-bottom: 10px;">
                <el-radio-group v-model="radio"
                                @input="radio_change">
                  <el-radio :label="1"
                            border
                            size="mini">None</el-radio>
                  <el-radio :label="2"
                            border
                            size="mini">Form-data</el-radio>
                  <el-radio :label="3"
                            border
                            size="mini">Json</el-radio>
                </el-radio-group>
                <!-- <el-radio v-model="radio2"
                              label="3"
                              border
                              size="mini">X-www-form-urlencoded</el-radio> -->

                <!-- <el-radio v-model="radio2"
                              label="5"
                              border
                              size="mini">Raw</el-radio>
                    <el-radio v-model="radio2"
                              label="6"
                              border
                              size="mini">Xml</el-radio> -->
              </div>
            </el-tab-pane>
            <el-tab-pane label="params"
                         name="first">
              <div style="margin-bottom: 10px; height: 28px">Query参数</div>
            </el-tab-pane>
            <el-tab-pane label="headers"
                         name="third">
              <div style="margin-bottom: 10px; height: 28px">Headers</div>
            </el-tab-pane>
            <el-tab-pane label="前置操作"
                         name="fourth">
              <div style="margin-bottom: 10px; height: 28px">前置操作</div>
            </el-tab-pane>
            <el-tab-pane label="后置操作"
                         name="fivth">
              <div style="margin-bottom: 10px; height: 28px">后置操作</div>
            </el-tab-pane>
            <!-- <el-tab-pane label="后置操作"
                     name="fiveth">后置操作</el-tab-pane> -->
            <!-- <el-tab-pane label="断言"
                         name="sixth">
              <div style="margin-bottom: 10px; height: 28px">前置操作</div>
            </el-tab-pane> -->
          </el-tabs>
        </el-form>
        <el-card>
          <div v-show="table_show"
               style="height: 300px">
            <el-table :data="params_data"
                      stripe
                      height="280px">
              <el-table-column prop="key"
                               label="key">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.key"
                            placeholder="key"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="value"
                               label="value">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.value"
                            placeholder="value"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="
                      operate"
                               label="操作">
                <template slot-scope="scope">
                  <el-button size="mini"
                             type="danger"
                             icon="el-icon-delete"
                             @click="deleteRow(scope.$index, scope.row)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div>
              <el-button type="primary"
                         icon="el-icon-plus"
                         size="mini"
                         style="width: 100%"
                         @click="addRow">新增一行
              </el-button>
            </div>
          </div>

          <div v-show="text_show"
               style="height: 300px">
            <el-input type="textarea"
                      :rows="14"
                      placeholder="请输入Json格式"
                      v-model="apiInfo.json_data">
            </el-input>
          </div>
          <div v-show="none_show"
               style="height: 300px;"><el-empty description="暂无传参"></el-empty>
          </div>

          <div v-show="assert_show">
            <el-table :data="apiInfo.assert_data"
                      height="280px"
                      stripe>
              <el-table-column prop="assert_type"
                               label="断言方式">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.assert_type"
                             placeholder="请选择断言方式">
                    <el-option label="状态码"
                               value="response_code"></el-option>
                    <el-option label="键值对"
                               value="key_value"></el-option>
                    <el-option label="字符串"
                               value="key"></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="data"
                               label="断言数据">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.data"
                            placeholder="$.data.xxxx"></el-input>
                </template>
              </el-table-column>

              <el-table-column prop="
operate"
                               label="操作">
                <template slot-scope="scope">
                  <el-button size="mini"
                             type="danger"
                             icon="el-icon-delete"
                             @click="deleteRow(scope.$index, scope.row)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>

            <div>
              <el-button type="primary"
                         icon="el-icon-plus"
                         size="mini"
                         style="width: 100%"
                         @click="addRow">新增一行
              </el-button>
            </div>
          </div>
          <div v-show="post_show"
               style="height: 300px;overflow: auto;">

            <div class="center-grid"
                 style="margin-bottom: 20px;">
              <el-button type="primary"
                         icon="el-icon-plus"
                         size="mini"
                         style="width: 100%"
                         @click="addPanel">提取变量
              </el-button>
            </div>

            <div v-for="(pos, index) in apiInfo.post_data"
                 :key="index">
              <el-collapse v-model="activeNames"
                           accordion>
                <el-collapse-item :name="index"
                                  title="提取变量">
                  <el-button type="danger"
                             icon="el-icon-delete"
                             circle
                             size="mini"
                             style="margin-left: 95%;"
                             @click="deletePanel(index)"></el-button>
                  <div class="center-grid">
                    <el-form :model="pos"
                             size="mini"
                             inline
                             :rules="rules"
                             ref="globalForm"
                             label-width="100px"
                             class="demo-ruleForm">
                      <el-form-item label="变量类型"
                                    prop="global_type">
                        <el-select v-model="pos.global_type"
                                   placeholder="请选择变量类型">
                          <el-option label="全局变量"
                                     value="all"></el-option>

                        </el-select>
                      </el-form-item>
                      <el-form-item label="变量名称"
                                    prop="global_name">
                        <el-input v-model="pos.global_name"></el-input>
                      </el-form-item>

                      <el-form-item label="提取来源"
                                    prop="res_type">
                        <el-select v-model="pos.res_type"
                                   placeholder="提取来源">
                          <el-option label="Response JSON"
                                     value="json"></el-option>

                        </el-select>
                      </el-form-item>

                      <el-form-item label="提取路径"
                                    prop="global_path">
                        <el-input v-model="pos.global_path"
                                  placeholder="如：$.data.book[0].title"></el-input>
                      </el-form-item>

                    </el-form>

                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
          <div v-show="pre_show"
               style="height: 300px">

            <el-table :data="params_data"
                      stripe
                      height="280px">
              <el-table-column prop="api_id"
                               label="前置接口">
                <template slot-scope="scope">

                  <el-select v-model="scope.row.api_id"
                             style="width: 100%;"
                             placeholder="请选择前置接口">
                    <el-option v-for="list_info in apiList"
                               :key="list_info.id"
                               :label="list_info.id + '：' + list_info.title"
                               :value=list_info.id></el-option>

                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="
                      operate"
                               label="操作">
                <template slot-scope="scope">
                  <el-button size="mini"
                             type="danger"
                             icon="el-icon-delete"
                             @click="deleteRow(scope.$index, scope.row)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div>
              <el-button type="primary"
                         icon="el-icon-plus"
                         size="mini"
                         style="width: 100%"
                         @click="addRow">新增一行
              </el-button>
            </div>
          </div>
        </el-card>

      </el-col>
      <el-col :span="8">

        <el-card style="height: 340px;margin-top: 92px;">
          <div style="margin-bottom: 10px; height: 28px">响应结果</div>
          <div v-if="res_json != null"
               class="scroll-container">
            <json-viewer :value="res_json"
                         :expand-depth="5"
                         copyable
                         sort></json-viewer>

            <!-- <json-format v-model="res_json"></json-format> -->
            <!-- <div v-html="highlightedJson"></div> -->
            <!-- <pre>{{formatJson(res_json)}}</pre> -->
            <!-- <textarea v-model="jsonString"></textarea> -->
            <!-- <button @click="jsonString = formatJson(jsonString)">格式化JSON</button> -->

          </div>

        </el-card>

      </el-col>
    </el-row>

  </div>

</template>

<script>
import JsonViewer from 'vue-json-viewer'
import 'vue-json-viewer/style.css'
export default {
  name: "apiDetail",
  components: {
    JsonViewer
  },
  data () {
    return {
      hostList: [
        { 'id': '001', 'name': 'Beaver环境' },
        { 'id': '002', 'name': 'AU品牌' },
        { 'id': '003', 'name': 'PU品牌' },
      ],
      activeNames: ['0'],
      panels: [{}], // 初始有一个折叠面板
      textarea: "",
      table_show: false,
      text_show: false,
      none_show: true,
      post_show: false,
      pre_show: false,
      assert_show: false,
      options: [],
      value: "",
      value1: "",
      month1: "",
      month2: "",

      params_data: [{ "key": "", "value": "" }],
      tabPosition: "left",
      radio: 1,
      radio_name: 1,
      tab_name: "params",

      methodList: [
        { key: "02", value: "POST" },
        { key: "01", value: "GET" },
        { key: "03", value: "PUT" },
        { key: "04", value: "DELETE" },
      ],
      apiInfo: null,
      globalForm: {
        global_name: '',
        global_type: '',
        res_type: '',
        global_path: '',

      },
      obj_id: null,
      activeName: "second",
      res_json: null,
      myApiData: {},
      data_type: "",
      body_data: "",

      apiList: [],
      rules: {
        global_name: [
          { required: true, message: '请输入变量名称', trigger: 'blur' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        global_path: [
          // { required: true, message: '请选择活动区域', trigger: 'change' }
          { required: true, message: '请输入提取路径', trigger: 'blur' }
        ],
      }
    }
  },
  created () {

  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    async getApiList () {
      const { data: res } = await this.$http.get(
        "py/server/autoApi/getApiList?obj_id=" + this.obj_id
      );
      console.log(res)
      if (res.success) {
        this.apiList = res.data;
        console.log(this.apiList)
      } else {
        this.$message.error(res.msg);
      }
    },
    radio_change (lebal) {
      this.radio_name = lebal;
      if (this.radio_name == "1") {
        this.none_show = true;
        this.table_show = false;

        this.text_show = false;
      } else if (this.radio_name == "2") {
        this.table_show = true;
        this.text_show = false;

        this.none_show = false;
        this.params_data = this.apiInfo.body_data;
        this.apiInfo.data_type = "form-data";
      } else {
        this.table_show = false;

        this.text_show = true;
        this.none_show = false;
        this.apiInfo.data_type = "json";
        // this.params_data = this.apiInfo.json_data
      }
    },
    tabClick (args) {
      this.tab_name = args._props.name;
      const tabName = args._props.name;
      const tabLabel = args._props.label;

      if (tabLabel == "params") {
        this.table_show = true;
        this.pre_show = false
        this.none_show = false;
        this.text_show = false;
        this.assert_show = false;
        this.post_show = false;
        this.params_data = this.apiInfo.params_data;

      } else if (tabLabel == "body") {
        this.params_data = this.apiInfo.body_data;
        this.table_show = false;
        this.none_show = true;
        this.pre_show = false
        this.post_show = false;
        this.assert_show = false;
        this.text_show = false;
        this.radio = 1;
      } else if (tabLabel == "headers") {
        this.params_data = this.apiInfo.headers_data;
        this.table_show = true;
        this.pre_show = false
        this.none_show = false;
        this.assert_show = false;
        this.post_show = false;
        this.text_show = false;
      } else if (tabLabel == "后置操作") {

        this.pre_show = false
        this.table_show = false;
        this.post_show = true;
        this.none_show = false;
        this.assert_show = false;
        this.text_show = false;
      } else if (tabLabel == '前置操作') {
        this.params_data = this.apiInfo.pre_data;
        this.getApiList()
        this.pre_show = true
        this.table_show = false;
        this.post_show = false;
        this.none_show = false;
        this.assert_show = false;
        this.text_show = false;
      }
      else if (tabLabel == "断言") {
        this.params_data = this.apiInfo.assert_data;
        this.table_show = false;
        this.post_show = false;
        this.none_show = false;
        this.assert_show = true;
        this.text_show = false;
      }
    },
    //增加行数据
    addRow () {
      if (this.params_data == undefined) {
        this.params_data = new Array();
      }
      let obj = {};

      this.params_data.push(obj);
    },

    //删除行数据
    deleteRow (index) {
      console.log(index);
      this.params_data.splice(index, 1);
    },
    async onSubmit () {

      this.apiInfo.response = this.res_json
      this.apiInfo.obj_id
      console.log(this.apiInfo)
      const { data: res } = await this.$http.post(
        "py/sever/autoApi/saveApi",
        this.apiInfo
      );
      if (res.success == true) {
        if (this.apiInfo.id == '') {
          this.$store.commit('changeCommit', true)
        }
        this.$message.success(res.msg);

        // this.$router.go(0);
      } else {
        this.$message.error(res.msg);
      }
    },
    async sendRequest () {
      this.apiInfo.response = this.res_json
      const { data: res } = await this.$http.post("py/sever/autoApi/sendRquest", this.apiInfo)

      if (res.success) {
        this.res_json = res
        this.$message.success('请求成功')
      } else {
        this.$message.error(res.msg)
      }


    },
    formatJson (jsonString) {
      try {
        const jsonObj = JSON.parse(jsonString);
        return JSON.stringify(jsonObj, null, 2);
      } catch (error) {
        return jsonString;
      }
    },
    addPanel () {
      let obj = {};

      this.apiInfo.post_data.push(obj); // 添加一个新的空对象到折叠面板数组
    },
    deletePanel (index) {
      console.log(index);
      this.apiInfo.post_data.splice(index, 1);
    },
    baocun () {
      console.log("点击保存")

      console.log(this.apiInfo)
    },


  },
};
</script>

<style  scoped>
.el-row {
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #042a82;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.scroll-container {
  overflow: auto;
  height: 300px; /* 根据需要设置高度 */
}
.center-grid {
  display: grid;
  place-items: center; /* 水平垂直居中 */
}
</style>
