import Vue from 'vue'
import App from './App.vue'
import router from '.'
import './plugins/element.js'
import './assets/css/global.css'
import axios from 'axios'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'default-passive-events'
import { ElementTiptapPlugin } from 'element-tiptap'
import moment from 'moment'
import store from './store'; // 引入 store 文件

// import element-tiptap 样式
import 'element-tiptap/lib/index.css'
// 引入自定义样式
import './assets/css/custom.css'
// 安装 element-tiptap 插件
import $ from 'jquery'

window.jQuery = $
window.$ = $

Vue.directive('highlight',{
	bind(el,binding,vnode){
		const {value} = binding;
		if(value && typeof value==='object'){
			const {hWord,word} = value;
			el.innerHTML = word.replace(new RegExp(hWord,'ig'),(a)=>{
				return `<span style="color:red">${a}</span>`
			})
		}
	},
	update(el,binding,vnode){
		const {value} = binding;
		if(value&&typeof value==='object'){
			const {hWord,word} = value;
			el.innerHTML = word.replace(new RegExp(hWord,'ig'),(a)=>{
				return `<span style="color:red;font-weight:bold">${a}</span>`
			})
		}
	}
})

Vue.use(ElementTiptapPlugin, {
  lang: 'zh' // 设置语言为中文
})

Vue.use(VueQuillEditor)

Vue.prototype.$http = axios

Vue.prototype.$moment = moment

axios.defaults.baseURL = '/'
Vue.config.productionTip = false
axios.interceptors.request.use((config) => {
  config.headers.token = window.sessionStorage.getItem('token')
  return config
})
// Vue.directive('title', {
//   inserted: function (el) {
//     document.title = el.getAttribute('title')
//   }
// })

new Vue({
  router,
	store,//注册store
  render: (h) => h(App)
}).$mount('#app')
